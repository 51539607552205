import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/combined_logo.png";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import "./menu.css";

function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dropDownHandler = (index) => {
    navigate("/section-quiz", { state: { index } });
    handleClose();
  };

  return (
    <div className="Menu row mb-5 border-bottom border-danger">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <img
          src={logo}
          style={{ marginRight: "10px", height: "auto", width: "300px" }}
          alt="img"
          height="45"
        />
        <div className="brandDiv">
          <Link className="navbar-brand" to="/section-introduction">
            DevSecOps Maturity Analysis
          </Link>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/section-introduction">
                Introduction
              </Link>
            </li>
            <li className="nav-item">
              {/* <Link className="nav-link" to="/section-quiz">
                Questionare
              </Link> */}
              <Button
                id="basic-button"
                style={{
                  // marginTop: "3px",
                  color: "red",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                Sections
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => dropDownHandler(0)}>
                  XENA - Agility
                </MenuItem>
                <MenuItem onClick={() => dropDownHandler(1)}>
                  XENA - Adoption
                </MenuItem>
                <MenuItem onClick={() => dropDownHandler(2)}>XENA - Source Control Management</MenuItem>
                <MenuItem onClick={() => dropDownHandler(3)}>
                  XENA - Jenkins Pipeline Manager
                </MenuItem>
                <MenuItem onClick={() => dropDownHandler(4)}>
                  XENA - Releases
                </MenuItem>
                <MenuItem onClick={() => dropDownHandler(5)}>XENA - Site Reliability</MenuItem>
                <MenuItem onClick={() => dropDownHandler(6)}>XENA - Tools</MenuItem>
              </Menu>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/results">
                Results
              </Link>
            </li>

            <li className="nav-item">
              {/* <Link className="nav-link" to="/about">
                About
              </Link> */}
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
