import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./welcome.css";
import Combined_logo from "../../Assets/combined_logo.png";

const defaultValues = {
  associateName: "",
  functionalArea: "",
  buName: "",
  appId: "",
  appName: "",
  techPlatform: "",
  btContact: "",
  tsContact: "",
  gxpStatus: ""
};

function Welcome(props) {
  const [formValues, setFormValues] = useState(defaultValues);
  const { dispatch } = props;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  let history = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();

    history("/section-quiz");

    dispatch({ type: "FORM_DATA", value: { ...formValues } });
  };

  return (
    <div className="row">
      <h1 className="text-center header">Introduction</h1>

      <p>
        The goal of this questionnaire is to help J&J Team assess the
        current XENA/DevSecOps maturity and to help understand what might be the
        appropriate roadmap and planned next steps for your XENA and DevSecOps Adoption journey.
      </p>

      <p>
        Please submit the questionaire and extract CSV report and email to DL-Consumer-Analytics-POD-TS-CICD@its.jnj.com,
        adding in your project name and app name in subject line.
      </p>
      
      <p>You can view the summary of your current state by generating a PDF 
        on the Results page.</p>

      <div>
        <form className="col-md-6 mx-auto " onSubmit={handleSubmit}>
          <p className="para">Please fill the below details to proceed</p>
          <div className="form-group my-3">
            <label htmlFor="name">Associate </label>
            <input
              type="text"
              className="form-control"
              value={formValues.associateName}
              name="associateName"
              onChange={handleInputChange}
              placeholder="Enter Associate Name"
              required
            />
          </div>
          <div className="form-group my-3">
            <label>Functional Area, Group</label>
            <select
              className="drop-down"
              name="functionalArea"
              value={formValues.functionalArea}
              onChange={handleInputChange}
              required
            >
              <option value={""} disabled>
                Enter Functional Area
              </option>

              <option value={"Mll"}>Mll</option>
              <option value={"Commercial"}>Commercial</option>
              <option value={"Consumer"}>Consumer</option>
              <option value={"Corporate"}>Corporate</option>
              <option value={"R&D"}>R&D</option>
              <option value={"supply chain"}>Supply Chain</option>
            </select>
          </div>
          <div className="form-group my-3">
            <label htmlFor="bu_name">BU Name, Sub Group</label>
            <input
              type="text"
              className="form-control"
              name="buName"
              onChange={handleInputChange}
              value={formValues.buName}
              placeholder="Enter BU Name"
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="fop">App ID</label>
            <input
              type="text"
              className="form-control"
              name="appId"
              onChange={handleInputChange}
              value={formValues.appId}
              placeholder="App ID (i.e. ITS-ABCD)"
              required
            />
          </div>

          <div className="form-group my-3">
            <label htmlFor="technologystack"> Application Name</label>
            <input
              type="text"
              className="form-control"
              name="appName"
              placeholder="Enter Application Name"
              value={formValues.appName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="technologystack"> Technology/Platform</label>
            <input
              type="text"
              className="form-control"
              placeholder="i.e. Java, Spring Boot, Maven, DynamoDB"
              name="techPlatform"
              value={formValues.techPlatform}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="technologystack"> BT Contact</label>
            <input
              type="text"
              className="form-control"
              name="btContact"
              placeholder="Enter Business Technolgy Contact"
              value={formValues.btContact}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="technologystack"> TS Contact</label>
            <input
              type="text"
              className="form-control"
              name="tsContact"
              placeholder="Enter Technology Solutions Contact"
              value={formValues.tsContact}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group my-3">
            <label htmlFor="technologystack"> App Type GxP/Non GxP</label>
            <input
              type="text"
              className="form-control"
              name="gxpStatus"
              placeholder="Enter GxP or Non GxP"
              value={formValues.gxpStatus}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-danger mx-auto">
              Next
            </button>
          </div>
        </form>
      </div>

      <div className="combined">
        <img className="comImg" src={Combined_logo} alt="combined-logo" />
      </div>
    </div>
  );
}

export default Welcome;
