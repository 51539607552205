import { Link } from 'react-router-dom';
import './about.css'
function About() {

    return (
        <div className="about row">
            <div className="col-md-6 sec-1">
                <h1>Improve Your DevSecOps Capability</h1>
                <p>This online DevSecOps Maturity Assessment questionnaire will help you understand your current strengths and weaknesses and then recommend resources that can support you in taking the next steps on your DevSecOps journey.</p>
                <div className="text-center">
                <Link className="btn btn-primary" to='/section-quiz'>Start the Questionare</Link>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <h1>Understand Where You Are</h1>
                    <p>Our set of carefully designed questions across 7 different areas will help you quickly establish your current level of DevSecOps maturity. You can view the results online as well as downloading them in CSV format for more detailed analysis.</p>
                </div>
                <div className="col-md-6">
                    <h1>Identify Your Next Steps</h1>
                    <p>Current DevSecOps maturity status, solution recommendations will be provided by J&JXena,CTSDevSecOps Team once you respond to the Questionnaire and share the output CSV file.</p>
                </div>
            </div>

        </div>
    );
}

export default About;