import React, { useState, useEffect } from "react";
import { XenaQuizData } from "../XenaQuizData";
import QuizEle from "./quizEle";
import { useNavigate, useLocation } from "react-router-dom";
import "./Quiz.css";

function Quizz(props) {
  const location = useLocation();
  const [state, setState] = useState({
    userAnswer: [],
    currentIndex: 0,
    options: [],
    quizEnd: false,
    score: 0,
    disabled: true,
    categoryList: [],
    filteredQuiz: [],
  });

  const [score, setScore] = useState([]);
  let [count, setCount] = useState([]);
  const [disable, setDisable] = useState(true);
  const [abc, setAbc] = useState(false);
  const [prevdisable, setPrevDisable] = useState(true);
  // const [finalscore, setFinalScore] = useState([]);
  const { dispatch } = props;
  let history = useNavigate();
  const {
    question,
    type,
    options,
    currentIndex,
    quizEnd,
    filteredQuiz,
    categoryList,
  } = state;

  const loadQuiz = () => {
    const newCategoryList = [
      ...new Set(
        XenaQuizData.map((ele) => {
          return ele.category;
        })
      ),
    ];

    const newFilteredQuiz = XenaQuizData.filter((ele) => {
      if (ele.category === newCategoryList[currentIndex]) return ele;
      else return "";
    });

    setState((prev) => {
      return {
        ...prev,
        filteredQuiz: newFilteredQuiz,
        categoryList: newCategoryList,
      };
    });
  };

  const { index } = location.state || {};
  useEffect(() => {
    setState({
      ...state,
      currentIndex: index || 0,
    });
    // setAbc(true);
    // handleSubmit();
  }, [index]);

  const handleSubmit = () => {
    history("/results");
  };

  const nextQuestionHandler = (e) => {
    const newIndex = currentIndex + 1;
    setState({
      ...state,
      currentIndex: newIndex,
      userAnswer: null,
    });

    setDisable(true);
  };
  const skipHandler = (e) => {
    const newIndex = currentIndex + 1;
    setState({
      ...state,
      currentIndex: newIndex,
    });

    if(currentIndex === categoryList?.length - 1 ){
      history("/results");

    }
  };
  const prevQuestionHandler = (e) => {
    const newIndex = currentIndex - 1;

    setState({
      ...state,
      currentIndex: newIndex,
      userAnswer: null,
    });
  };

  useEffect(() => {
    loadQuiz();
  }, []);

  const checkAnswer = (answer) => {
    setState(() => {
      return {
        userAnswer: answer,
        disabled: false,
      };
    });
  };

  useEffect(() => {
    if (currentIndex === 0) {
      setPrevDisable(true);
    } else {
      setPrevDisable(false);
    }
    const newFilteredQuiz = XenaQuizData.filter((ele) => {
      if (ele.category === categoryList[currentIndex]) return ele;
      else return "";
    });

    if (newFilteredQuiz.length) {
      setState((prev) => {
        return {
          ...prev,
          filteredQuiz: newFilteredQuiz,
        };
      });
    }
  }, [currentIndex]);

  const finishHandler = () => {
    if (currentIndex === XenaQuizData.length - 1) {
      setState({
        quizEnd: true,
      });
    }
    history("/results");
  };

  const handleChange = (e, qid) => {
    let que = XenaQuizData.filter((ele) => {
      return ele.id === qid;
    });
    let data = score;
    let tcount=count;

    if (que[0].type === "radio") {
      if (que[0].answer === e.target.value) {
        data[qid] = que[0].score;
      } else {
        data[qid] = 0;
      }
      tcount[qid]= 1;
    } else {
      if (!data[qid]) {
        data[qid] = 0;
      }
      let len = que[0].answer.length;
      let sans = que[0].score / len;
      let sta = false;

      for (let i = 0; i < que[0].answer.length; i++) {
        if (que[0].answer[i] === parseInt(e.target.id)) sta = true;
      }

      if (sta) {
        if (e.target.checked) {
          data[qid] = data[qid] + sans;
        } else {
          data[qid] = data[qid] - sans;
        }
      } else {
        if (e.target.checked) {
          data[qid] = data[qid] - sans;
        } else {
          data[qid] = data[qid] + sans;
        }
      }
      if (e.target.checked) {
        tcount[qid]=tcount[qid]? ++tcount[qid] : 1;
      }
      else{
        tcount[qid]=tcount[qid]? --tcount[qid] : 1;
      }

    }
    setCount(tcount);
    //enabling next button

    dispatch({ type: "SCORE", value: score });
    let filteredScore = score.slice(
      filteredQuiz[0].id,
      filteredQuiz[filteredQuiz.length - 1].id + 1
    );

    filteredScore = filteredScore.filter((ele) => {
      return ele != null;
    });

    // if (filteredScore.length === filteredQuiz.length) {
      let t=1
      // count.reduce((total,curr)=>{
      //   t=curr===0?0:++t
      //   return ''
      // })
        let fcount=count.slice(filteredQuiz[0].id,
          filteredQuiz[filteredQuiz.length - 1].id + 1);
        fcount.reduce((total,curr)=>{
          t=curr===0?0:++t
          return ''
        })
    if (t >= filteredQuiz.length) {
      setDisable(false);
      const calculatedScore = filteredScore.reduce((total, curr) => {
        return (total += curr);
      }, 0);

      dispatch({
        type: "STORE_RESULT",
        value: {
          category: categoryList[currentIndex],
          totalQuestion: filteredQuiz.length,
          totalScore: calculatedScore,
        },
      });
    }
    else{
      setDisable(true);

    }
    setScore(data);
  };

  if (quizEnd) {
    return (
      <div>
        <h1>Game Over. Final score is {state.score} points</h1>
      </div>
    );
  }

  return (
    <div className="mainContent">
      <h1 className="section-title">{categoryList[currentIndex]}</h1>
      {filteredQuiz &&
        filteredQuiz.map((ele) => (
          <QuizEle key={ele.id} {...ele} handleChange={handleChange} />
        ))}
      <div className="navigation text-center">
        {currentIndex < categoryList?.length - 1 && (
          <button
            className="btn btn-danger"
            disabled={disable}
            onClick={(e) => {
              nextQuestionHandler(e);
            }}
          >
            Next Section
          </button>
        )}

        {currentIndex === categoryList?.length - 1 && (
          <button
            className="btn btn-danger"
            disabled={disable}
            onClick={finishHandler}
          >
            Finish
          </button>
        )}
        <button
          style={{ marginLeft: "10px" }}
          className="btn btn-danger"
          disabled={disable}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          style={{ marginLeft: "10px" }}
          className="btn btn-danger"
          disabled={!disable}
          onClick={skipHandler}
        >
          Skip
        </button>
      </div>
    </div>
  );
}

export default Quizz;
