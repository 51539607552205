import {
   STORE_RESULT,
   FORM_DATA,
   SCORE
    
  } from "./action";
  const initialState = {
    score:[]
  }
  
  const formReducer = (state = initialState, action) => {
    const { type, value } = action;
    switch (type) {
  case STORE_RESULT:
        return { ...state, result: [...state.result,value]};
        case FORM_DATA:
            return { ...state, formData:{...state.formData,value }};
        case SCORE:
          return {...state,score:value};
    
      default:
        return { ...state };
    }
  };
  
  export default formReducer;