import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0000FF",
    },
    bar1: {
      main: "#D3D3D3",
    },
    bar2: {
      main: "#DC3444",
    },
    bar3: {
      main: "#FFC432",
    },
    bar4: {
      main: "#72B41A",
    },

    bar5: {
      main: "#006400",
    },

    secondary: {
      light: "#0066ff",
      main: "#0044ff",

      contrastText: "#ffcc00",
    },

    contrastThreshold: 3,

    tonalOffset: 0.2,
  },
});
