import React, { useReducer } from "react";
import "./App.css";
import About from "./Components/About/about";
import Header from "./Components/Navbar/menu";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Welcome from "./Components/Welcome/welcome";

import Quiz from "./Components/Sections/Quizz";
import formValues from "./Redux/formValues";
import formReducer from "./Redux/formReducer";

import BarChart from "./Components/Results/BarChart";
import Excel from "./Components/Excel/excel";
import { theme } from "./Theme/theme";
import { ThemeProvider } from "@mui/material/styles";

const App = () => {
  const [store, dispatch] = useReducer(formReducer, formValues);
  return (
    <ThemeProvider theme={theme}>
      <div className="App container-fluid">
        <Header />

        <Routes>
          <Route exact path="/about" element={<About />}></Route>

          <Route
            exact
            path="/section-introduction"
            element={<Welcome dispatch={dispatch} />}
          ></Route>

          <Route
            exact
            path="/section-quiz"
            element={<Quiz dispatch={dispatch} />}
          ></Route>
          <Route
            exact
            path="/results"
            element={<BarChart store={store} />}
          ></Route>
          <Route exact path="/excel" element={<Excel store={store} />}></Route>

          <Route
            exact
            path="/"
            element={<Navigate to="/section-introduction" />}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
