export const XenaQuizData = [
  // Xena Adoptions Questions
  {
    id: 0,
    question:
      "Has the team taken the XENA training course?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Adoption",
    answer: "Yes",
    score: 1,
  },
  {
    id: 1,
    question:
      "Which XENA Workstreams have been adopted for this project? Select all that apply.",
      options: [
          { id: 1, value: "EAT Jenkins/Artifactory"},
          { id: 2, value: "CloudX Container Service" },
          { id: 3, value: "VPCx Platform Patterns" },
          { id: 5, value: "JnJ API Platforms" },
          { id: 6, value: "Jenkins Pipeline Manager (JPM)" },
          { id: 7, value: "Infrastructure as Code (IaC)" },
          { id: 8, value: "Test Engineering and Automation" },
          { id: 9, value: "Data Science and MLOps" },
          { id: 10, value: "Reliability Engineering" },
          { id: 11, value: "DevSecOps" },
          { id: 12, value: "Application Logging and Monitoring" }
          
        ],
    type: "checkbox",
    category: "XENA Adoption",
    answer: 1,
    score: 1,
  },
  {
    id: 2,
    question:
    "Does the J&J Application/Platform team utilize Ask XENA (Teams Channel), to share and receive advice on XENA Workstreams?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Adoption",
    answer: "Yes",
    score: 1,
  },
  {
    id: 3,
    question:
      "Does the team manage their project with JIRA (User Stories, Tasks, Backlogs, Roadmaps)",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Agility",
    answer: "Yes",
    score: 1,
  },
  {
    id: 4,
    question:
      "Does the team practice standups and retrospectives?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Agility",
    answer: "Yes",
    score: 1,
  },
  {
    id: 5,
    question:
      "Does the team utilize Enterprise Agile (EAT) tools for asynchronous communication and collaboration (for example Jira, Confluence, etc)",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Agility",
    answer: "Yes",
    score: 1,
  },
  {
    id: 6,
    question:
    "Are work items that are blocked, swiftly identified and then people collaborate to rectify the situation?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Agility",
    answer: "Yes",
    score: 1,
  },
  {
    id: 7,
    question:
      "Does the J&J Application/Platform team have a new release/package, version of the product available every 1-2 weeks?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable" }
    ],
    type: "radio",
    category: "XENA Agility",
    answer: "Yes",
    score: 1,
  },
  {
    id: 8,
    question:
      "Does the team use smart commits to integrate with Jira Workflow?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
     
    ],
    type: "radio",
    category: "XENA SCM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 9,
    question:
      "Does the team have a Source Control Management strategy in place? (Code Review, Pull Requests, Branching/Merging)",
    options: [
     
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable" },
    ],
    type: "radio",
    category: "XENA SCM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 10,
    question:
      "Does the team use Bitbucket for version control?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA SCM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 11,
    question:
      "Does the team utilize Static Code Analysis (SonarQube, Chekmarx) to identify and address technical debt?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA SCM",
    answer: "No",
    score: 1,
  },
  {
    id: 12,
    question:
      "Does the team create Pull Requests early to collaborate on Features that are in progress and receive feedback?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA SCM",
    answer: "No",
    score: 1,
    
  },
  {
    id: 13,
    question:
      "Does the team follow the XENA standards for branching and merging? (Gitflow) ",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "Other" },
      { id: 3, value: "No branching and merging strategy"},
    ],
    type: "radio",
    category: "XENA SCM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 14,
    question:
      "Does the team have CI/CD enabled through Jenkins Pipeline Manager (JPM)?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "Other" },
      { id: 3, value: "Not Enabled"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 15,
    question:
      "Does the team have the Lint stage enabled for their JPM pipeline?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 16,
    question:
      "Does the team have the Unit Test stage enabled for their JPM pipeline?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 17,
    question:
      "Does the team have the Static Code Analysis stage enabled for their JPM pipeline (SonarQube and/or Checkmarx?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes", 
    score: 1,
  },
  {
    id: 18,
    question:
      "Does the team have the Package stage enabled for their JPM pipeline?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 19,
    question:
      "Does the team have the Publish stage enabled for their JPM pipeline?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 20,
    question:
      "Does the team have the Build Analysis stage enabled for their JPM pipeline?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 21,
    question:
      "Does the team have the Deploy stage enabled for their JPM pipeline?",
    options: [
      { id: 1, value: "Yes, Fully-Automated" },
      {id: 2, value: "Partially Automated (CD Job)"},
      { id: 3, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes, Fully Automated",
    score: 1,
  },
  {
    id: 22,
    question:
      "Does the team have the Integration/Regression Test stages (Robot or Hyperion) enabled for their JPM pipeline?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA JPM",
    answer: "Yes",
    score: 1,
  },
  {
    id: 23,
    question:
      "Does the team, or a QA team, perform Automated testing? (Integration, Regression, Performance)",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Testing",
    answer: "Yes",
    score: 1,
  },
  {
    id: 24,
    question:
      "Does the team write Unit Tests for all new features?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      { id: 3, value: "Not Applicable"}
    ],
    type: "radio",
    category: "XENA Testing",
    answer: "Yes",
    score: 1,
  },
  {
    id: 25,
    question:
      "Does the team practice TDD (Test Driven Development) or BDD (Behavior Driven Development) practices?",
    options: [
      { id: 1, value: "TDD" },
      { id: 2, value: "BDD" },
      { id: 3, value: "No"}
    ],
    type: "radio",
    category: "XENA Testing",
    answer: "BDD",
    score: 1,
  },
  {
    id: 26,
    question:
      "What is the current release cycle? (1-2 week, 3-4 week, 4-6 week)",
    options: [
      { id: 1, value: "1-2 Weeks" },
      { id: 2, value: "3-4 Weeks" },
      { id: 3, value: "5-6 Weeks" },
    ],
    type: "checkbox",
    category: "XENA Releases",
    answer: "1-2 Weeks",
    score: 1,
  },
  {
    id: 27,
    question:
      "Which of the following are measured by the team (tick all that apply)?",
    options: [
      {
        id: 1,
        value:
          "Elapsed lead time to deliver valuable changes (from initial request to production)",
      },
      { id: 2, value: "Frequency of deployments into production" },
      { id: 3, value: "Change failure rate" },
      { id: 4, value: "Time to restore service after a failure" },
    ],
    type: "checkbox",
    category: "XENA Releases",
    answer: [1, 2],
    score: 1,
  },
  {
    id: 28,
    question:
      "Do you have Change Request approval process to deploy into Higher env / GXP env?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Releases",
    answer: "No",
    score: 1,
  },
  {
    id: 29,
    question:
      "Does the team publish Release Notes or maintain a changelog as part of their CI/CD pipeline?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA Releases",
    answer: "Yes",
    score: 1,
  },
  {
    id: 30,
    question:
      "Does the team have any Observability or Monitoring tools in place?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA SRE",
    answer: "Yes",
    score: 1,
  },
  {
    id: 31,
    question:
      "Which of the following are measured by the team (tick all that apply)?",
    options: [
      { id: 1, value: "Latency" },
      { id: 2, value: "Traffic" },
      { id: 3, value: "Errors" },
      { id: 4, value: "Saturation" },
    ],
    type: "checkbox",
    category: "XENA SRE",
    answer: [1, 2, 3, 4],
    score: 1,
  },
  {
    id: 32,
    question:
      "Does the team use any form of log aggregation tool?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA SRE",
    answer: "Yes",
    score: 1,
  },
  {
    id: 34,
    question:
      "Does the team make use of Alerting to address production problems swiftly?",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
    ],
    type: "radio",
    category: "XENA SRE",
    answer: "Yes",
    score: 1,
  },
  {
    id: 35,
    question:
      "What are some of the technologies used by the team? (Select any that may apply)",
    options: [
      { id: 1, value: "Python (FastAPI, PySpark, Flask, Django, etc)" },
      { id: 2, value: "JS/TS (React, Angular, Node.js, Next.js, Nest.js, Jest, Mocha, etc...)" },
      { id: 3, value: "Java (Spring Boot, JUnit, Maven, Gradle, etc)" },
      { id: 4, value: "Containers (Docker, Podman, OCI Images, etc)" },
      { id: 5, value: "Automated Testing (Robot, Hyperion, Selenium)" },
      { id: 6, value: "PHP (Drupal, Acquia)" },
      { id: 7, value: "Dotnet (MSBUILD, ASP.NET, Blazor, etc)" },
      { id: 8, value: "Docker, Kubernetes, AKS, EKS" }
    ],
    type: "checkbox",
    category: "XENA Tools",
    answer: "Python",
    score: 1,
  },
  {
    id: 36,
    question:
      "Which platforms does the application/s run on?",
    options: [
      { id: 1, value: "AWS - EKS" },
      { id: 2, value: "AWS - EC2" },
      { id: 3, value: "Azure - AKS" },
      { id: 4, value: "Azure - VM" },
      { id: 5, value: "Other Cloud Provider" },
      { id: 6, value: "On Prem" },
    ],
    type: "checkbox",
    category: "XENA Tools",
    answer: [1,3],
    score: 1,
  },
  {
    id: 37,
    question:
      "Does the team follow the XENA recommended approach to Containerization? (All applications are containerized and deployed to EKS/AKS?)",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      
    ],
    type: "radio",
    category: "XENA Tools",
    answer: "Yes",
    score: 1,
  },
  {
    id: 38,
    question:
      "Is Infrastructure provisioned through XENA's CloudX Automated Pipelines? (EKS/AKS Clusters, S3, etc)",
    options: [
      { id: 1, value: "Yes" },
      { id: 2, value: "No" },
      
    ],
    type: "radio",
    category: "XENA Tools",
    answer: "Yes",
    score: 1,
  },

  
];
