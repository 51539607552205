import { XenaQuizData } from "../XenaQuizData";
import { CSVLink, CSVDownload } from "react-csv";

function Excel(props) {
  let excelData = [];

  excelData = XenaQuizData.map((e, index) => {
    return {
      id: e.id,
      category: e.category,
      question: e.question,
      answer: e.answer,
      "max score": e.score,
      score: props.store.score[index],
    };
  });

  return (
    <div className="text-center">
      <CSVLink
        data={excelData}
        filename={ props?.store?.formData?.value?.appName + "-xena-assessment.csv"}
        className="btn btn-danger"
        
      >
        Download Excel
        
      </CSVLink>
    </div>
  );
}
export default Excel;
