/* eslint-disable */
import * as React from "react";
import { styled } from "@mui/material/styles";
import { XenaQuizData } from "../XenaQuizData";

import Typography from "@mui/material/Typography";
import "./result.css";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Excel from "../Excel/excel";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

const ref = React.createRef();

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 40,
  borderRadius: 10,

  [`&.${linearProgressClasses.colorPrimary}`]: {},

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

const handleExportWithFunction = (event) => {
  savePDF(ref.current, { paperSize: "A1" });
};

function CustomizedProgressBars(props) {
  const [state, setState] = React.useState({
    categoryList: [],
  });
  console.log(props, "props");
  const { categoryList } = state;
  const { result } = props.store;

  const loadCategory = () => {
    const newCategoryList = [
      ...new Set(
        XenaQuizData.map((e) => {
          return e.category;
        })
      ),
    ];

    setState((prev) => {
      return {
        ...prev,

        categoryList: newCategoryList,
      };
    });
  };

  const progressColor = (progressBar) => {
    if (progressBar == 0) {
      return "bar1";
    }
    if (progressBar <= 30) {
      return "bar2";
    }
    if (progressBar <= 80) {
      return "bar3";
    }
    if (progressBar <= 100) {
      return "bar4";
    }

    return "bar5";
  };

  React.useEffect(() => {
    loadCategory();
  }, []);
  return (
    <div style={{ marginTop: "10px" }} ref={ref}>
      <div className="row">
        <h3 style={{ textAlign: "center", marginBottom: "5%" }}>Quiz Result</h3>
        <div className="col-md-7 bar-data">
          {categoryList.map((list, index) => {
            const selectedItem = result.find((i) => i.category === list) || {};
            console.log(selectedItem, "abc");
            return (
              <div className="parent" key={index}>
                <div className="child1">{list} : </div>
                {/* <div> */}
                <BorderLinearProgress
                  className="child2"
                  color={progressColor(
                    (selectedItem.totalScore / selectedItem.totalQuestion) *
                      100 || 0
                  )}
                  style={{ backgroundColor: "#D3D3D3" }}
                  variant="determinate"
                  value={
                    (selectedItem.totalScore / selectedItem.totalQuestion) *
                      100 || 0
                  }
                />
                {selectedItem.totalScore ? (
                  <Typography
                    className="child3"
                    variant="body2"
                    color="text.secondary"
                  >{`${Math.round(
                    (selectedItem.totalScore / selectedItem.totalQuestion) *
                      100 || 0
                  )}%`}</Typography>
                ) : (
                  <Typography
                    className="child3"
                    variant="body2"
                    color="text.secondary"
                  >
                    {"0%"}
                  </Typography>
                )}
              </div>
            );
          })}
        </div>
        <div className="col-md-4 form-data">
          <div>
            Associate Name : {props?.store?.formData?.value?.associateName}
          </div>

          <div>
            Functional Area : {props?.store?.formData?.value?.functionalArea}
          </div>

          <div>BU Name : {props?.store?.formData?.value?.buName}</div>

          <div>App ID : {props?.store?.formData?.value?.appId}</div>

          <div>App Name : {props?.store?.formData?.value?.appName}</div>

          <div>
            Tech Platform : {props?.store?.formData?.value?.techPlatform}
          </div>

          <div>BT Contact : {props?.store?.formData?.value?.btContact}</div>

          <div>TS Contact : {props?.store?.formData?.value?.tsContact}</div>

          <div>Compliance : {props?.store?.formData?.value?.gxpStatus}</div>
        </div>
      </div>
      <div className="row">
        <div className="text-center col-md-6">
          <button className="btn btn-danger" onClick={handleExportWithFunction}>
            Generate pdf
          </button>
        </div>
        <div className="col-md-6">
          <Excel store={props.store}></Excel>
        </div>
      </div>
    </div>
  );
}
export default CustomizedProgressBars;

