import React from "react";
import "./Quiz.css";

function QuizEle(props, id, question, options, type) {
  return (
    <div className="question-container">
      <div className="question border-bottom rounded-top">
        <h2 className="section-question" key={props.id}>
          {props.question}
        </h2>
      </div>
      <div className="options rounded-bottom">
        {props.options &&
          props.options.map((opt, index) => {
            return (
              <div className="form-check" key={index}>
                <input
                  type={props.type}
                  id={opt.id}
                  value={opt.value}
                  name={props.question}
                  className="form-check-input"
                  onChange={(e) => {
                    props.handleChange(e, props.id);
                  }}
                />
                <label className="form-check-label">{opt.value}</label>
                <br />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default QuizEle;
